import currentZone from 'services/cache/currentZone'

export const update = zone => {
  currentZone.write(zone)
  return {
    type: 'zone/update',
    payload: zone
  }
}

const initialState = currentZone.read()

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'zone/update':
      return action.payload
    default:
      return state
  }
}

export default reducer
