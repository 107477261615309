import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import zone from 'services/reducers/zone'

const reducer = combineReducers({
  form,
  zone
})

export default reducer
