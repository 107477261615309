import { createStore } from 'redux'

import rootReducer from './rootReducer'
import enhancer from './enhancer'

const initialState = window.__INITIAL_STATE__

const store = createStore(rootReducer, initialState, enhancer)

if (process.env.NODE_ENV !== 'production') {
  window.__STORE__ = store
}

export default store
