import { API_ENDPOINT } from 'services/constants'
import Client from 'lyjs/es/request/Client'
import cacheToken from 'services/cache/token'
import userCache from 'services/cache/user'
// import Raven from 'raven-js'

const client = new Client(
  {
    baseURL: API_ENDPOINT,
    timeout: 30000,
    headers: {
      'Authentication-Token': cacheToken.read()
    }
  },
  {
    response: [
      response => response,
      error => {
        if (process.env.NODE_ENV !== 'production') {
          console.warn(error)
        }
        // 用户没有授权
        if (error.response && error.response.status === 401) {
          cacheToken.destroy()
          userCache.destroy()
          window.location = '/#/auth'
          return Promise.reject(error)
        }
        if (process.env.REACT_APP_DEPLOYMENT_ENV === 'production') {
          // Raven.captureException(error)
        }
        return Promise.reject(error)
      }
    ]
  }
)

export default client
