import jwtDecode from 'jwt-decode'
import findIndex from 'lodash.findindex'
import cacheToken from 'services/cache/token'
import userCache from 'services/cache/user'
import client from 'services/utils/requestClient'

/**
 * 更新authToken信息
 *
 * @param {String} authToken
 */
export const setAuthToken = authToken => {
  client.updateConfig({
    headers: {
      'Authentication-Token': authToken
    }
  })
}

/**
 * 登陆
 *
 * @param {String}} username
 * @param {String} password
 */
export const login = async (username, password) => {
  try {
    const { data } = await client.request.post(`/login`, {
      username,
      password
    })
    const token = data.response.user.authentication_token
    setAuthToken(token)
    const user = await getSelfUser()
    cacheToken.write(token)
    userCache.write(user)
  } catch (error) {
    throw error
  }
}

/**
 * 登出
 */
export const logout = async () => {
  setAuthToken('')
  cacheToken.destroy()
  userCache.destroy()
  window.location = '/#/auth'
}

/**
 * 获取用户(兼职搭配师)账号列表
 */
export const getUsers = async () => {
  try {
    const { data } = await client.request.get('/users')
    return data
  } catch (error) {
    throw error
  }
}

/**
 * 获取单个用户信息
 *
 * @param {number} userId
 */
export const getUser = async userId => {
  try {
    const { data } = await client.request.get(`/users/${userId}`)
    return data.user
  } catch (error) {
    throw error
  }
}

/**
 * 创建新用户
 *
 * @param {String} username
 * @param {String} nick
 */
export const createUser = async (username, nick) => {
  try {
    await client.request.post('/users', {
      username,
      nick
    })
  } catch (error) {
    throw error
  }
}

/**
 * 创建用户auth_token
 *
 * @param {number} userId
 */
export const generateUserAuthToken = async userId => {
  try {
    const { data } = await client.request.post(`/users/${userId}/tokens`)
    return data
  } catch (error) {
    throw error
  }
}

/**
 * 关联店铺和搭配师账号
 *
 * @param {number} userId
 * @param {number} shopId
 */
export const addAccountToShop = async (userId, shopId) => {
  try {
    const { shops } = await getUser(userId)
    for (let shop of shops) {
      if (shop.id === shopId) {
        // throw new Error('店铺用户已经关联')
        return
      }
    }
    await client.request.put(`/users/${userId}`, {
      shops: shops.concat({
        id: shopId
      })
    })
  } catch (error) {
    throw error
  }
}

/**
 * 删除店铺和搭配师账号关联
 *
 * @param {number} userId
 * @param {number} shopId
 */
export const removeAccountFromShop = async (userId, shopId) => {
  try {
    const { shops } = await getUser(userId)
    const index = findIndex(shops, shop => shop.id === shopId)
    if (index === -1) {
      return
    }
    const newShops = [].concat(shops)
    newShops.splice(index, 1)
    await client.request.put(`/users/${userId}`, {
      shops: newShops
    })
  } catch (error) {
    throw error
  }
}

/**
 * 是否有管理账号的权限
 */
export const hasAccountsPermission = () => {
  try {
    const token = cacheToken.read()
    const data = jwtDecode(token)
    return data.roles.includes('admin')
  } catch (error) {
    return false
  }
}

/**
 * 获取自己的用户信息
 */
export const getSelfUser = async () => {
  try {
    const { data } = await client.request.get(`/account`)
    return data.user
  } catch (error) {
    throw error
  }
}

/**
 * 获取Look数量
 *
 * @param {Number} userId
 * @param {String} startTime
 * @param {String} endTime
 */
export const fetchLookCount = async (userId, startTime, endTime) => {
  try {
    const { data } = await client.request.get(`/users/${userId}/prior-looks`, {
      params: {
        start_time: startTime,
        end_time: endTime
      }
    })
    const { unique_spu_count: count } = data
    return {
      count
    }
  } catch (error) {
    throw error
  }
}
