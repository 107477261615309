import React from 'react'
import { Redirect } from 'react-router-dom'
import cacheToken from 'services/cache/token'
import * as AccountApi from 'services/api/account'
import currentZone from 'services/cache/currentZone'

export default class Authorization extends React.Component {
  render() {
    const { match, location, children } = this.props
    if (match.query.auth_token) {
      cacheToken.write(match.query.auth_token)
      AccountApi.setAuthToken(match.query.auth_token)
      return <Redirect to={location.pathname} />
    }
    if (location.pathname === '/auth' && !!cacheToken.read()) {
      return <Redirect to={'/tagging-task'} />
    }
    if (
      location.pathname.indexOf('/auth') === -1 &&
      !currentZone.read() &&
      location.pathname !== '/zones'
    ) {
      return <Redirect to={'/zones'} />
    }
    return children
  }
}
