export const APP_NAME = process.env.REACT_APP_NAME
export const APP_VERSION = process.env.REACT_APP_VERSION
export const APP_DESCRIPTION = process.env.REACT_APP_DESCRIPTION
export const APP_SHA = process.env.REACT_APP_SHA || Date.now()

export const WEBSITE_NAME = process.env.REACT_APP_WEBSITE_NAME

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '/'

export const SESSION_API_ENDPOINT =
  process.env.REACT_APP_SESSION_API_ENDPOINT ||
  'https://api.leyanbot.com/session-search/v1/'

export const SESSION_API_ENDPOINT2 =
  process.env.REACT_APP_SESSION_API_ENDPOINT2 ||
  'https://api.leyanbot.com/session-search/v2/'

export const Sentence = {
  Role: {
    unprocessed: 'unprocessed',
    majority: 'majority',
    minority: 'minority'
  }
}

export const Cluster = {
  Status: {
    unprocessed: 'unprocessed',
    good: 'good',
    bad: 'bad',
    unsure: 'unsure',
    ignored: 'ignored'
  }
}

export const CandidateCluster = {
  Status: {
    unprocessed: 'unprocessed',
    good: 'good',
    bad: 'bad',
    unsure: 'unsure',
    ignored: 'ignored',
    commited: 'commited'
  }
}

export const TASK_PAGE_SIZE = 100

export const CLUSTER_TAGS = ['已抽查', '待重新聚类']
