import React, { Fragment } from 'react'
import { Provider } from 'react-redux'
import Loadable from 'react-loadable'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { LocaleProvider, Layout } from 'antd'
import locale from 'antd/lib/locale-provider/zh_CN'

import {
  APP_NAME,
  APP_VERSION,
  APP_DESCRIPTION,
  APP_SHA
} from 'services/constants'
import store from 'services/store'
import history from 'services/history'
import AuthorizationComponent from './components/AuthorizationComponent'

const Auth = Loadable({
  loading: () => null,
  loader: () => import('scenes/Auth')
})

const ManageGroups = Loadable({
  loading: () => null,
  loader: () => import('scenes/ManageGroups')
})

const Nav = Loadable({
  loading: () => null,
  loader: () => import('scenes/Nav')
})

const ClusterList = Loadable({
  loading: () => null,
  loader: () => import('scenes/ClusterList')
})

const Stats = Loadable({
  loading: () => null,
  loader: () => import('scenes/Stats')
})

const Zones = Loadable({
  loading: () => null,
  loader: () => import('scenes/Zones')
})

const TaskOverview = Loadable({
  loading: () => null,
  loader: () => import('scenes/TaskOverview')
})

const ExploreClusters = Loadable({
  loading: () => null,
  loader: () => import('scenes/ExploreClusters')
})

// const TaggingTask = Loadable({
//   loading: () => null,
//   loader: () => import('scenes/TaggingTask')
// })

function App() {
  return (
    <LocaleProvider locale={locale}>
      <Provider store={store}>
        <Router history={history}>
          <Fragment>
            <Helmet>
              <meta
                name="app-meta"
                content={`name=${APP_NAME},version=${APP_VERSION},description=${APP_DESCRIPTION},sha=${APP_SHA}`}
              />
            </Helmet>
            <AuthorizationComponent>
              <Layout /*style={{ height: '100%' }}*/>
                <Route path="/" component={Nav} />
                <div style={{ marginTop: 64 }}>
                  <Switch>
                    <Route exact path="/auth" component={Auth} />
                    <Route path="/groups" component={ManageGroups} />
                    <Route path="/tagging-task" component={ClusterList} />
                    <Route path="/stats" component={Stats} />
                    <Route path="/zones" component={Zones} />
                    <Route path="/task-overview" component={TaskOverview} />
                    <Route
                      path="/explore-clusters"
                      component={ExploreClusters}
                    />
                    {/* <Route path="/task/:taskNumber" component={TaggingTask} /> */}
                    <Redirect from="*" to="/tagging-task" />
                  </Switch>
                </div>
              </Layout>
            </AuthorizationComponent>
          </Fragment>
        </Router>
      </Provider>
    </LocaleProvider>
  )
}

export default App
